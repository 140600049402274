import React from 'react'
import MatchedEntity from './MatchedEntity'
import SourceEntity from './SourceEntity'
import TargetEntity from './TargetEntity'
const BusinessEntity = (props) => {
    const sourceEntity = props.sourceEntity
    const targetEntity = props.targetEntity

    // Merge entities with the same entityName
    const mergedEntities = sourceEntity.map(sourceEntity => {
        const matchingTargetEntity = targetEntity.find(targetEntity => targetEntity.entityName === sourceEntity.entityName);
        if (matchingTargetEntity) {
            return {
                ...sourceEntity,
                //totalCount: sourceEntity.totalCount + matchingTargetEntity.totalCount,
                sourceCount: sourceEntity.totalCount,
                targetCount: matchingTargetEntity.totalCount

            };
        } else {
            return {
                ...sourceEntity,
                sourceCount: sourceEntity.totalCount,
                //targetCount: matchingTargetEntity.totalCount
            }
        }
    }).concat(targetEntity.filter(targetEntity => !sourceEntity.find(sourceEntity => sourceEntity.entityName === targetEntity.entityName)));

    console.log(mergedEntities)


  return (
    <>
        {mergedEntities.map((entity, i) => (
            <MatchedEntity
                key={i}
                entityName={entity.entityName}
                sourceCount={entity.sourceCount}
                targetCount={entity.targetCount}
            />
        ))}
        {/* {
            sourceEntity.map((sourceItem, i) =>
                <div key={i}>
                    {
                        targetEntity.map((targetItem, i) =>
                            sourceItem.entityName === targetItem.entityName ?
                                <React.Fragment key={i}>
                                    <MatchedEntity 
                                        entityName={sourceItem.entityName}
                                        sourceCount={sourceItem.totalCount}
                                        targetCount={targetItem.totalCount}
                                    />
                                </React.Fragment>
                            : null
                        )
                    }
                </div>
            )
        } */}
        {/* {
            sourceEntity.map((sourceItem, i) => 
                <SourceEntity
                    key={i}
                    entityName={sourceItem.entityName}
                    sourceCount={sourceItem.totalCount}
                />
            )
        }
        {
            targetEntity.map((targetItem, i) => 
                <TargetEntity
                    key={i}
                    entityName={targetItem.entityName}
                    targetCount={targetItem.totalCount}
                />
            )
        } */}
    </>
  )
}

export default BusinessEntity





