import React, {createContext, useState} from "react";
import Routes from "./Components/Routes/Routes";
function App() {
  return (
    <div className="container-fluid">
      <Routes />
    </div>
  );
}

export default App;
