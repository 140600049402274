import React from 'react'
import Skeleton from '@mui/material/Skeleton';

const SelectConnectorSkeleton = () => {
    return (
        <div className='cstmoverflow'>
            <div className='row mt-3 padding-20-new'>
                <div className='col-lg-3 mb-3 conhover'>
                    <Skeleton variant="rounded" width={140} height={137} className="connectorSkelton" />
                </div>
                <div className='col-lg-3 mb-3 conhover'>
                    <Skeleton variant="rounded" width={140} height={137} className="connectorSkelton" />
                </div>
                <div className='col-lg-3 mb-3 conhover'>
                    <Skeleton variant="rounded" width={140} height={137} className="connectorSkelton" />
                </div>
                <div className='col-lg-3 mb-3 conhover'>
                    <Skeleton variant="rounded" width={140} height={137} className="connectorSkelton" />
                </div>
                <div className='col-lg-3 mb-3 conhover'>
                    <Skeleton variant="rounded" width={140} height={137} className="connectorSkelton" />
                </div>
                <div className='col-lg-3 mb-3 conhover'>
                    <Skeleton variant="rounded" width={140} height={137} className="connectorSkelton" />
                </div>
                <div className='col-lg-3 mb-3 conhover'>
                    <Skeleton variant="rounded" width={140} height={137} className="connectorSkelton" />
                </div>
                <div className='col-lg-3 mb-3 conhover'>
                    <Skeleton variant="rounded" width={140} height={137} className="connectorSkelton" />
                </div>
                <div className='col-lg-3 mb-3 conhover'>
                    <Skeleton variant="rounded" width={140} height={137} className="connectorSkelton" />
                </div>
                <div className='col-lg-3 mb-3 conhover'>
                    <Skeleton variant="rounded" width={140} height={137} className="connectorSkelton" />
                </div>
                <div className='col-lg-3 mb-3 conhover'>
                    <Skeleton variant="rounded" width={140} height={137} className="connectorSkelton" />
                </div>
            </div>
        </div>
    )
}

export default SelectConnectorSkeleton
