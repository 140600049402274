import React from 'react'
import Product from './FeatuerImages/product.svg'
import Customer from './FeatuerImages/customer-and-address.svg'
import Inventory from './FeatuerImages/inventory.svg'
import Tierpricelevel from './FeatuerImages/tierpricelevel.svg'
import Cancelorder from './FeatuerImages/cancelorder.svg'
import Configurableproduct from './FeatuerImages/configurableproduct.svg'
import Shipment from './FeatuerImages/shipment.svg'
import Salesorder from './FeatuerImages/Salesorder.svg'
import Businesstaxpostinggroup from './FeatuerImages/business-tax-posting-group.svg'
import Customerdiscountgroup from './FeatuerImages/customer-discount-group.svg'
import Discountprice from './FeatuerImages/discount-price.svg'
import Editorder from './FeatuerImages/edit-order.svg'
import Itemdiscountgroup from './FeatuerImages/item-discount-group.svg'
import Itemtaxpostinggroup from './FeatuerImages/item-tax-posting-group.svg'
import Taxposting from './FeatuerImages/tax-posting.svg'

const FeatuerImage = (props) => {
    return (
        <>
            {
                (props.displayName == "Tier Prices") ? <img src={Tierpricelevel} /> :
                (props.displayName == "Shipment") ? <img src={Shipment} /> :
                (props.displayName == "ConfigurableProduct") ? <img src={Configurableproduct} /> :
                (props.displayName == "Cancel Order") ? <img src={Cancelorder} /> :
                (props.displayName == "Product") ? <img src={Product} /> :
                (props.displayName == "Customer") ? <img src={Customer} /> :
                (props.displayName == "Inventory") ? <img src={Inventory} /> :
                (props.displayName == "Price Level") ? <img src={Tierpricelevel} /> :
                (props.displayName == "Customer Group") ? <img src={Customer} /> : 
                (props.displayName == "Invoice") ? <img src={Inventory} />:
                (props.displayName == "Sales Order") ? <img src={Salesorder} />:
                (props.displayName == "Customer Discount Group") ? <img src={Businesstaxpostinggroup} />:
                (props.displayName == "Discount Price") ? <img src={Customerdiscountgroup} />:
                (props.displayName == "Item Discount Group") ? <img src={Discountprice} />:
                (props.displayName == "Edit Order") ? <img src={Editorder} />:
                (props.displayName == "Tax Posting") ? <img src={Itemdiscountgroup} />:
                (props.displayName == "Tax Business Posting Group") ? <img src={Itemtaxpostinggroup} />:
                (props.displayName == "Tax Product Posting Group") ? <img src={Taxposting} />:null
            }
        </>
    )
}

export default FeatuerImage
