import React from 'react'
import { useHistory } from 'react-router-dom'

const EntityCount = (props) => {
    const history = useHistory();
    var entityLength = props.allEntity.reduce((unique, o) => {
        if(!unique.some(obj => obj.entityName === o.entityName)) {
          unique.push(o);
        }
        return unique;
    },[]);

    const viewDetails = () =>{
        localStorage.setItem('summaryInfo', JSON.stringify(
            {
                sourceShortName: props.sourceShortName,
                targetShortName: props.targetShortName,
                instancesId: props.instancesId,
                sourceEntity: props.sourceEntity, 
                targetEntity:props.targetEntity,
                sourceId:props.sourceId,
                targetId:props.targetId
            }
        ))
        history.push(`/ViewSummary`);
    }


    return (
        <h3 className="subtitleNew">Business Entity ({entityLength.length})
            {
                entityLength.length > 0 ?
                <a className="float-right text-primary viewDetails" onClick={viewDetails}>View Details</a> :
                <button className="float-right text-primary no-border disabledNew" title="Installation setup is incomplete" disabled> View Details </button>
            }
        </h3>
    )
}

export default EntityCount