import React from 'react'
import ConnectorInfo from './SubComponents/ConnectorInfo'
import BusinessEntities from './BusinessEntities/BusinessEntities'
import SetupWarning from '../../../../../Alerts/WarningComponent/SetupWarning'
import NoData from '../../../../../Alerts/WarningComponent/NoEntities'
import SetupAction from './SetupAction/SetupAction'


const SelectedConnector = (props) => {
    const allEntity = props.businessEntitiesSource.concat(props.businessEntitiesTarget)
    var entityLength = allEntity && allEntity.reduce((unique, o) => {
        if(!unique.some(obj => obj.entityName === o.entityName)) {
          unique.push(o);
        }
        return unique;
    },[]);
    return (
        <div className='text-muted mt-4 mt-md-0'>
            <div className='card no-border no-boxshadow mb-0'>
                <div className="card-header card-titlenew">
                    <span className="float-left">{props.name}</span>
                    <span className="clearfix"></span>
                </div>
                <div className='card-body padding-btm-0'>
                    <ConnectorInfo
                        sourceName={props.sourceName}
                        sourceVersion={props.sourceVersion}
                        targetName={props.targetName}
                        targetVersion={props.targetVersion}
                        featureName={props.featureName}
                    />
                    <BusinessEntities
                        businessEntitiesSource={props.businessEntitiesSource}
                        businessEntitiesTarget={props.businessEntitiesTarget}
                        sourceName={props.sourceName}
                        targetName={props.targetName}
                        sourceShortName={props.sourceShortName}
                        targetShortName={props.targetShortName}
                        instancesId={props.instancesId}
                        sourceId={props.sourceId}
                        targetId={props.targetId}
                        key={props.key}
                    />
                    {!props.isSetupCompleted ?
                        <>
                            {/* <SetupWarning /> */}
                        </>:null
                    }
                    {
                        props.isSetupCompleted && entityLength.length === 0 ? 
                        <NoData /> : null
                    }
                </div>
            </div>
        </div>
    )
}
export default SelectedConnector