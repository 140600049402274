import React, { useEffect, useState } from 'react'
import API from "../../../../API/API"
import { Redirect } from 'react-router-dom';
import Installers from '../../Installers/Installers'
import ChildDownload from './ChildDownload';

const DownloadInstaller = (props) => {
    const [connectorId, setConnectorId] = useState()
    const [expiryTime, setExpiryTime] = useState()
    const [id, setId] = useState()
    const [instanceId, setInstanceId] = useState()
    const [key, setKey] = useState()
    const [result, setResult] = useState()
    const [sourceIsAuthenticationRequired, setSourceIsAuthenticationRequired] = useState()
    const [targetIsAuthenticationRequired, setTargetIsAuthenticationRequired] = useState()
    const [connectorInfo, setConnectorInfo] = useState()

    useEffect(() => {
        let connectorques=Object.entries(props.questions);
        connectorques=connectorques.filter(obj=>{
            if(obj[0]!=="isPaneOpen") { return {
                obj
            }}
        })
        let result1 = connectorques.map((obj) => { 
            return {
             "questionId":parseInt(obj[0]), 
             "answer": obj[1]
             }
        });
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        const body =
        {
            connectorVersionId: parseInt(props.versionId),
            "connectorName": `${props.name}`,
            "sourceVersion": props.sourceVersion.toString(),
            "targetVersion": props.targetVersion.toString(),
            "subscriptionAnswers":result1
        }
        API.post(`/Subscription`, body, {headers})
        .then((response) => {
            setConnectorId(response.data.connectorId)
            setExpiryTime(response.data.expiryTime)
            setId(response.data.id)
            setInstanceId(response.data.instanceId)
            setKey(response.data.ket)
            setResult(response.data.result)
            API.get(`/Client/connections/${response.data.connectorId}/instances/${response.data.instanceId}`, {headers})
            .then((response) => {
                window.localStorage.setItem("data",JSON.stringify(response.data))
                setSourceIsAuthenticationRequired(response.data.source && response.data.source.isAuthenticationRequired)
                setTargetIsAuthenticationRequired(response.data.source && response.data.target.isAuthenticationRequired)
                setConnectorInfo(response.data)
            })
        })
    },[])

    return (
        <>
            {
                (sourceIsAuthenticationRequired || targetIsAuthenticationRequired) ? <Redirect to="/Authentication"/> : 
                <ChildDownload 
                    connectorInfo = {connectorInfo}
                />
                
            }
        </>
  )
}

export default DownloadInstaller
