import React,{useEffect, useState} from 'react'
import $ from 'jquery'
import SlidingPane from "react-sliding-pane";
import DownloadInstaller from './DownloadInstaller/DownloadInstaller';

const SendPrerequisites = (props) => {
    const [state, setState] = useState({
        isPaneOpen: false,
    });
    const [questionsUpdated, setQuestionsUpdated] = useState()
    //const [allFilled, setAllFilled] = useState()

    const selectedPrerequisites = () => {
        let mainResult = [];
        var row = {};
        $("#defaultQuestioneries tr td").each(function(){
            var mnId = $(this).attr("id");
            var mnValueNew = $(this).text().trim()

            
            
            var mnValue = mnValueNew //mnValueNew === "true" ? true:mnValueNew === "false" ? false:mnValueNew




            if((mnValue === "")){
                //setAllFilled(false)
            }else{
                row[mnId] = mnValue
                mainResult.push(row);
                setQuestionsUpdated(row)
                setState({ isPaneOpen: true })
            }
        })
    }
    
    return (
        <>
            <button type="button" className="btn btn-primary waves-effect waves-light mr-1 subsubmit" onClick={selectedPrerequisites}>Submit</button>
            <SlidingPane overlayClassName="some-custom-overlay-class" isOpen={state.isPaneOpen} onRequestClose={() => {setState({ isPaneOpen: false });}} title="Add Connector" subtitle="Download Files">
                {/* <Installers /> */}
                <DownloadInstaller
                    name={props.name}
                    sourceName={props.sourceName}
                    targetName={props.targetName} 
                    connectorInfo={props.connectorInfo} 
                    featuerData={props.featuerData}
                    productTypeValue = {props.productTypeValue}
                    eventValue= { props.eventValue}
                    instance = {props.instance}
                    id={props.id}
                    questions={questionsUpdated} 
                    sourceVersion = {props.sourceVersion}
                    targetVersion ={props.targetVersion}
                    versionId ={props.versionId }
                />
            </SlidingPane>
        </>
    )
}
export default SendPrerequisites
